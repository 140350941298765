/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
html {
  scroll-behavior: smooth;
}
 /* .mad-info i{
  color: white!important;
}
.mad-header-section--sticked .mad-info i{
  color: black!important;
}
.mad-info{
  color: white!important;
}
.mad-header-section--sticked .mad-info{
color: black!important;
}
.mad-img>img {
  width: 90%!important;
} */
